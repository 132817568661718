<template>
  <div>
    <b-modal
      id="modal-users-tag-detail"
      cancel-variant="outline-secondary"
      cancel-title="Ok"
      ok-only
      ok-variant="primary"
      modal-class="modal-primary"
      centered
      size="lg"
      :title="$t('Tag type detail')"
    >
      <b-overlay :show="show" variant="transparent" no-wrap />
      <table
        class="
          table
          table-bordered
          table-responsive-lg
          table-white-space
          table-striped
          table-highlight
        "
      >
        <tr>
          <td class="table-td-header">{{ $t("Tag type") }}</td>
          <td>{{ tagData.name }}</td>
          <td class="table-td-header">{{ $t("Description") }}</td>
          <td>{{ tagData.description }}</td>
        </tr>
        <!-- <tr>
          <td class="table-td-header">{{ $t("Created at") }}</td>
          <td>{{ datetimeData.created_at || "n/a" }}</td>
          <td class="table-td-header">{{ $t("Updated at") }}</td>
          <td>{{ datetimeData.updated_at || "n/a" }}</td>
        </tr> -->
        <tr>
          <td class="table-td-header">{{ $t("Tags") }}</td>
          <td v-if="tagData.tags" colspan="3">
            <b-badge
              v-for="tag in tagData.tags"
              :key="tag.id"
              variant="primary"
              class="b-block mr-1"
              :style="`background-color: ${tag.color} !important;`"
            >
              {{ tag.name || "" }}
            </b-badge>
          </td>
        </tr>
      </table>
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BOverlay,
  BLink,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BInputGroup,
  BInputGroupPrepend,
  BModal,
  VBModal,
  BInputGroupAppend,
  BFormTextarea,
  BBadge,
} from "bootstrap-vue";
import { ref, onUnmounted } from "@vue/composition-api";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";
import useUsersTag from "./useUsersTag";

export default {
  components: {
    BOverlay,
    BLink,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BInputGroup,
    BInputGroupPrepend,
    BModal,
    BFormTextarea,
    BBadge,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  props: {
    tagId: {
      type: Number,
      required: false,
      default: null,
    },
    dateTime: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      tagData: {},
      datetimeData: {},
      show: false,
    };
  },
  watch: {
    async tagId(newVal) {
      if (newVal) {
        this.show = true;
        await this.getTagTypeData(newVal);
        this.datetimeData = this.dateTime;
        this.show = false;
      }
    },
  },
  methods: {
    async getTagTypeData(id) {
      await this.$store
        .dispatch("app-user-tag/fetchTagTypeDetail", id)
        .then((response) => {
          // eslint-disable-next-line prefer-destructuring
          this.tagData = response.data[0];
        });
    },
  },
  setup() {
    const { usersTags } = useUsersTag();

    return {
      usersTags,
    };
  },
};
</script>

<style lang="scss" scoped>
table.table-narrow td {
  padding: 10px !important;
}
.dark-layout .table-highlight .table-td-header {
  background-color: #343d55 !important;
}
.table-highlight td.table-td-header {
  background-color: #f3f2f7;
}
</style>
>
